<template>
  <div>
    <h2>
      404 ERROR
    </h2>
  </div>
</template>

<script>

export default {
  name: 'Page404',
  computed: {
    message () {
      return '找不到网页！'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
