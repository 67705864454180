import { getToken, setToken, removeToken, getDelAccountParams, setDelAccountParams, removeDelAccountParams } from '@/utils/auth'

const state = {
  token: {},
  delAccountParams: {},
  getToken: appName => {
    if (!state.token[appName]) {
      state.token[appName] = getToken(appName);
    }
    return state.token[appName];
  },
  getDelAccountParams: appName => {
    if (!state.delAccountParams[appName]) {
      state.delAccountParams[appName] = getDelAccountParams(appName);
    }
    return state.delAccountParams[appName];
  }
}

const mutations = {
  LOGOUT: (state, appName) => {
    mutations.REMOVE_TOKEN(state, appName);
    mutations.REMOVE_DEL_ACCOUNT_PARAMS(state, appName);
  },
  SET_TOKEN: (state, { appName, token }) => {
    state.token[appName] = token;
    setToken(appName, token)
  },
  REMOVE_TOKEN: (state, appName) => {
    state.token[appName] = null;
    removeToken(appName)
  },
  SET_DEL_ACCOUNT_PARAMS: (state, { appName, params }) => {
    state.delAccountParams[appName] = params;
    setDelAccountParams(appName, params);
  },
  REMOVE_DEL_ACCOUNT_PARAMS: (state, appName) => {
    state.delAccountParams[appName] = null;
    removeDelAccountParams(appName);
  },
}

export default {
  namespaced: true,
  state,
  mutations
}
