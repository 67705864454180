import LoadingVue from './loading.vue'
const $loading = {
  install: (Vue) => {
    // 通过 Vue.extend方法 获取LoadingComponent 组件类
    const TargetComponent = Vue.extend(LoadingVue);
    // new LoadingComponent得到组件的实例
    const vm = new TargetComponent();
    // 获取组件实例的html 并插入到body中
    const tpl = vm.$mount().$el;
    // 插入到body中
    document.body.appendChild(tpl);
    // 添加 显示loading方法
    Vue.prototype.$showLoading = (options) => {
      vm.show(options);
    }
    // 添加关闭loading方法
    Vue.prototype.$hideLoading = () => {
      vm.hide();
    }
  }
}

export default $loading;