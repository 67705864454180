import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { isEmpty } from '@/utils/validate';

Vue.use(VueRouter);

let routes = [
  // index
  {
    path: '',
    redirect: '/connector/account-deletion',
    hidden: true,
  },
];

const brands = [
  '3db',
  'acomax',
  'amigo',
  'amp',
  'avosdim',
  'azzurra',
  'bliss',
  'bloc-blinds',
  'brelhome',
  'brelmatter',
  'came',
  'connector',
  'diaz',
  'enyroom',
  'eurotronic',
  'futurol',
  'gamana',
  'gaviota',
  'knconnect',
  'levolor',
  'mamaba',
  'moritz',
  'motion',
  'mykrispol',
  'penta-home',
  'persol',
  'riel-americano',
  'rollux',
  'smart-awins',
  'smartlux',
  'smith',
  'total-shade',
  'ublock',
  'wellsun',
  'wynmotion',
]
brands.forEach(brand => {
  routes.push(
    {
      path: '/' + brand + '/account-deletion',
      component: () => import('@/views/' + brand + '/delaccount/confirm'),
      meta: { requireAuth: true, needDelAccountParams: true, appName: brand },
      hidden: true
    },
    {
      path: '/' + brand + '/account-deletion/success',
      component: () => import('@/views/' + brand + '/delaccount/success'),
      meta: { appName: brand },
      hidden: true
    },
    {
      path: '/' + brand + '/account-deletion/login',
      component: () => import('@/views/' + brand + '/delaccount/login'),
      meta: { title: "Login", appName: brand },
      hidden: true
    },
  )
})

routes.push(
  // 404
  {
    path: '*',
    component: () => import('@/views/error/404'),
    hidden: true
  })

const router = new VueRouter({
  mode: 'history',
  routes: routes
});
router.beforeEach((to, from, next) => {
  to.matched.some((route) => {
    if (route.meta.requireAuth || route.meta.needDelAccountParams) {
      const appName = route.meta.appName || 'connector';
      if ((route.meta.requireAuth && isEmpty(store.getters.token(appName))) // 有些页面需要用户登录
        || (route.meta.needDelAccountParams && isEmpty(store.getters.delAccountParams(appName)))) { // 删号页面需要特殊的参数
        displayTitle(to);
        next({
          path: '/' + appName + '/account-deletion/login',
          query: { redirect_uri: route.path }
        });
        return;
      }
    }
    displayTitle(to);
    next();
  });
});

function displayTitle(to) {
  if (to.meta.title) {//判断是否有标题
    // @ts-ignore
    document.title = to.meta.title;
  } else {
    document.title = '';
  }
}

// 解决路由重复错误：https://www.jianshu.com/p/0be859e4b353
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
export { routes };
