import { saveAs } from 'file-saver';
import axios from 'axios';
import { getToken } from '@/utils/auth';

const baseURL = '/api';

export default {

  /**
   * 使用url下载文件
   * @param {*} url 
   * @param {*} params 
   */
  url (url, params) {
    return new Promise((resolve, reject) => {
      var fullUrl = baseURL + url;
      axios({
        method: 'get',
        url: fullUrl,
        params: params,
        responseType: 'blob',
        headers: { 'Authorization': 'Bearer ' + getToken() }

      }).then(res => {
        // console.log('success');
        const blob = new Blob([res.data]);
        let filename = res.headers["content-disposition"].split(";")[1].split("filename=")[1];
        this.saveAs(blob, filename);
        resolve(filename);

      }).catch(error => {
        // console.log('fail');
        // console.log(error);
        if (error.response != undefined) {
          // 设置了responseType: 'blob'，axios会把data强制转为blob
          const data = error.response.data;
          const blob = new Blob([data]);
          //FileReader可以读取Blob内容
          var fr = new FileReader();
          //二进制转换成text
          fr.readAsText(blob);
          //转换完成后，调用onload方法
          fr.onload = function (e) {
            var msg = fr.result;
            reject(msg);
          };
        } else {
          reject(error);
        }
      });
    });
  },

  saveAs (text, name, opts) {
    saveAs(text, name, opts);
  }
};

