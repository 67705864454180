import Vue from 'vue'
import App from './App.vue'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import LoadingHUD from '@/components/LoadingHUD'
Vue.use(LoadingHUD)
// 各个品牌的全局组件
import ConnectorReminderView from '@/components/connector/ReminderView'
Vue.use(ConnectorReminderView)

import AcomaxReminderView from '@/components/acomax/ReminderView'
Vue.use(AcomaxReminderView)

// main.js
// import '@/assets/styles/index.scss' // global css

import "@/text/text.scss";
import '@/assets/icons' // icon

import router from './router'
import store from './store'
import plugins from './plugins' // plugins

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.use(plugins)

// Vue.prototype.$appCode = '92c9c09a-b7b5-4c6c-bbb9-028b761763d9';

window.onresize = function () {
  calcRam();
};

function calcRam () {
  var html = document.querySelector("html");
  var w = document.documentElement.clientWidth;
  var docEl = window.document.documentElement;
  var ww = docEl.getBoundingClientRect().width;
  if (ww > 480) {
    html.style.fontSize = "20.48px";
  }
  else if (ww < 282) {
    // 字体大小设置为12px以下时，有些浏览器会强行转换为12px，不如手动设置最小为12px
    html.style.fontSize = "12px";
  }
  else {
    html.style.fontSize = 16 * w / 375 + "px";
  }
}

new Vue({
  router: router,
  store: store,
  render: (h) => h(App),
  mounted () {
    calcRam();
  }
}).$mount('#app')


