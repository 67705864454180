import Cookies from 'js-cookie'

const TOKEN_KEY = 'TOKEN'
const DEL_ACCOUNT_PARAMS_KEY = 'DEL_ACCOUNT_PARAMS'


// /**
//  * 获取用户令牌
//  */
// export function getToken () {
//   return Cookies.get(TOKEN_KEY)
// }

// /**
//  * 设置用户令牌
//  * @param {*} token
//  */
// export function setToken (token) {
//   return Cookies.set(TOKEN_KEY, token)
// }

// /**
//  * 移除用户令牌
//  */
// export function removeToken () {
//   return Cookies.remove(TOKEN_KEY)
// }

// export function getDelAccountParams () {
//   const str = Cookies.get(DEL_ACCOUNT_PARAMS_KEY);
//   if (str) {
//     return JSON.parse(Cookies.get(DEL_ACCOUNT_PARAMS_KEY));
//   }
//   return undefined;
// }

// export function setDelAccountParams (params) {
//   return Cookies.set(DEL_ACCOUNT_PARAMS_KEY, JSON.stringify(params))
// }

// export function removeDelAccountParams () {
//   return Cookies.remove(DEL_ACCOUNT_PARAMS_KEY)
// }

/**
 * 获取用户令牌
 */
export function getToken(appName) {
  return Cookies.get(getTokenKey(appName))
}

/**
 * 设置用户令牌
 */
export function setToken(appName, token) {
  return Cookies.set(getTokenKey(appName), token)
}

/**
 * 移除用户令牌
 */
export function removeToken(appName) {
  return Cookies.remove(getTokenKey(appName))
}

export function getDelAccountParams(appName) {
  const str = Cookies.get(getDelAccountKey(appName));
  if (str) {
    return JSON.parse(str);
  }
}

export function setDelAccountParams(appName, params) {
  return Cookies.set(getDelAccountKey(appName), JSON.stringify(params))
}

export function removeDelAccountParams(appName) {
  return Cookies.remove(getDelAccountKey(appName))
}

function getTokenKey(appName) {
  if (appName) {
    return appName.toUpperCase() + "_" + TOKEN_KEY;
  } else {
    return TOKEN_KEY;
  }
}

function getDelAccountKey(appName) {
  if (appName) {
    return appName.toUpperCase() + "_" + DEL_ACCOUNT_PARAMS_KEY;
  } else {
    return DEL_ACCOUNT_PARAMS_KEY;
  }
}