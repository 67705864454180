import ReminderVue from './reminder.vue'
const $reminder = {
  install: (Vue) => {
    const TargetComponent = Vue.extend(ReminderVue);
    // 创建组件的实例
    const vm = new TargetComponent();
    const tpl = vm.$mount().$el;
    // 插入到body中
    document.body.appendChild(tpl);
    // 添加【显示】方法
    Vue.prototype.$acomax_showReminder = (content) => {
      vm.show(content, {
        type: 'alert',
        confirmText: 'OK',
      })
    }
    // 添加【隐藏】方法
    Vue.prototype.$acomax_hideReminder = () => {
      vm.hide()
    }
  }
}

export default $reminder;