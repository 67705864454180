<template>
  <!--
		mask是loading的背景 v-show控制loading显示消失
	-->
  <div class="mask" v-show="isShow">
    <div class="loading" :style="circleCss"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // loading默认不显示
      isShow: false,
      themeColor: "#438af3",
      defaultCss: {
        themeColor: "#438af3",
      }
    }
  },
  computed: {
    circleCss() {
      return {
        // border: '6px solid ' + this.themeColor, 
        // borderLeft: '6px solid transparent',
        /* 上面那种写法borderLeft不生效，可能顺序被打乱了。故我们单独设置4条边框 */
        borderLeft: '6px solid transparent',
        borderTop: '6px solid ' + this.themeColor,
        borderRight: '6px solid ' + this.themeColor,
        borderBottom:  '6px solid ' + this.themeColor,
      }
    }
  },
  methods: {
    show(options) {
      if (Object.prototype.toString.call(options) === '[object Object]') { // 确保用户传递的是一个对象
        this.themeColor = options.themeColor || this.defaultCss.themeColor;
      }
      // this.themeColor = this.defaultCss.themeColor;
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
  }
}
</script>
<style lang="scss" scoped>
// 定义动画 控制 loading旋转
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;

  .loading {
    width: 30px;
    height: 30px;
    border: 6px solid #438af3;
    border-radius: 21px;
    border-left-color: transparent;
    animation: rotate 500ms infinite;
  }
}
</style>