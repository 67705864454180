<template>
  <transition name="confirm-fade">
    <div v-if="isShowConfirm" class="my-confirm" @click.stop="clickFun('clickCancel')">
      <div class="confirm-content-wrap" @click.stop>
        <h3 class="my-confirm-title" v-show="titleText != ''">{{ titleText }}</h3>
        <p class="my-confirm-content">{{ content }}</p>
        <div class="my-operation">
          <button v-if="type === 'confirm'" class="my-cancel-btn" type="button" @click="clickFun('clickCancel')">
            {{ cancelText }}
          </button>
          <button class="my-confirm-btn" type="button" @click="clickFun('clickConfirm')">
            {{ confirmText }}
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>
 
<script type="text/ecmascript-6">
export default {
  data() {
    return {
      isShowConfirm: false, // 用于控制整个窗口的显示/隐藏
      titleText: '操作提示', // 提示框标题
      content: 'Say Something ...', // 提示框的内容
      cancelText: '取消', // 取消按钮的文字
      confirmText: '确认', // 确认按钮的文字
      type: 'confirm', // 表明弹框的类型：confirm - 确认弹窗（有取消按钮）；alert - 通知弹框（没有取消按钮）
      outerData: null // 用于记录外部传进来的数据，也可以给外部监听userBehavior，事件的函数提供判断到底是哪个事件触发的
    }
  },
  methods: {
    show(content, config) {
      this.content = content || 'Say Something ...'

      if (Object.prototype.toString.call(config) === '[object Object]') {
        // 确保用户传递的是一个对象
        this.titleText = config.titleText || 'Reminder'
        this.cancelText = config.cancelText || 'Cancel'
        this.confirmText = config.confirmText || 'OK'
        this.type = config.type || 'confirm'
        this.outerData = config.data || null
      }

      this.isShowConfirm = true
    },
    hide() {
      this.isShowConfirm = false
      this.titleText = 'Reminder'
      this.cancelText = 'Cancel'
      this.confirmText = 'OK'
      this.type = 'confirm'
      this.outerData = null
    },
    clickFun(type) {
      // this.$emit('userBehavior', type, this.outerData)
      this.hide()
    }
  }
}
</script>
 
<style lang="scss" scoped>
.my-confirm {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  /* 这里防止当用户长按屏幕，出现的黑色背景色块，以及 iPhone 横平时字体的缩放问题 */
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  /*flex 布局*/
  display: flex;
  /*实现垂直居中*/
  align-items: center;
  /*实现水平居中*/
  justify-content: center;
  text-align: center;
}

/* 进入和出去的动画 */
.confirm-fade-enter-active {
  animation: opacity 0.3s;
}

.confirm-fade-enter-active .confirm-content-wrap {
  animation: scale 0.3s;
}

.confirm-fade-leave-active {
  animation: outOpacity 0.2s;
}

/* 包裹层容器样式 */
.confirm-content-wrap {
  /* background-color: yellow; */
  background-color: #fff;
  width: 280px;
  margin: 0 auto;
  border-radius: 5px;
  z-index: 999;
  overflow: hidden;
}

/* 顶部标题部分 */
.my-confirm-title {
  /* background-color: red; */
  margin: 0;
  padding: 18px;

  font-family: SFProText-Heavy;
  font-size: 18px;
  color: #000000;
  // letter-spacing: -0.43px;
  text-align: center;
  font-weight: 800;
}

/* 中间内容部分 */
.my-confirm-content {
  /* background-color: brown; */
  margin: 0;
  padding: 15px;
  max-height: 200px;
  overflow-y: auto;

  opacity: 0.5;
  font-family: SFProText-Regular;
  font-size: 14px;
  color: #000000;
  // letter-spacing: -0.47px;
  text-align: center;
  font-weight: 400;
}

/* 底部按钮样式 */
.my-operation {
  display: flex;
  border-top: 1px solid #eee;
  margin-bottom: 0;
}

.my-operation .my-cancel-btn,
.confirm-btn {
  flex: 1;
}

.my-confirm-btn {
  background-color: white;
  height: 48px;
  width: 100%;
  border: none;

  font-family: SFProText-Bold;
  font-size: 16px;
  color: #000000;
  // letter-spacing: -0.39px;
  text-align: center;
  font-weight: 700;
}

.my-confirm-btn:focus,
.my-confirm-btn:hover {
  background-color: #c8c8c8;
}

/* 进来的动画 */
@keyframes opacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }

  60% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

/* 出去的动画 */
@keyframes outOpacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


// 定制化样式
.my-confirm-title {
  color: black;
  text-align: center;
}

.my-confirm-btn {
  color: black;
}
</style>